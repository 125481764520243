<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.userDetail.menu"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.userDetail.list.title"
        ></app-i18n>
      </h1>

      <app-user-detail-list-toolbar></app-user-detail-list-toolbar>
      <app-user-detail-list-filter></app-user-detail-list-filter>
      <app-user-detail-list-table></app-user-detail-list-table>
    </div>
  </div>
</template>

<script>
import UserDetailListFilter from '@/modules/user-detail/components/user-detail-list-filter.vue';
import UserDetailListTable from '@/modules/user-detail/components/user-detail-list-table.vue';
import UserDetailListToolbar from '@/modules/user-detail/components/user-detail-list-toolbar.vue';

export default {
  name: 'app-user-detail-list-page',

  components: {
    [UserDetailListFilter.name]: UserDetailListFilter,
    [UserDetailListTable.name]: UserDetailListTable,
    [UserDetailListToolbar.name]: UserDetailListToolbar,
  },
};
</script>

<style></style>
